@import url('https://fonts.googleapis.com/css2?family=Roca+One&display=swap');

/* Container for the About Us section */
.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Your background color */
  padding: 60px;
  flex-direction: row;
  gap: 40px;
  overflow: hidden;
}

/* Styling for the text section */
.about-text {
  flex: 1;
  font-family: 'Montserrat';
  color: #000000;
  animation: fade-in 1.5s ease-out;
}

.about-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: justify;
}

/* Styling for the image section */
.about-image {
  flex: 1;
  text-align: right;
  animation: slide-in-left 1.5s ease-out;
}

.about-image img {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 8px;
}

/* Animation Keyframes */
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive design for tablet */
@media (max-width: 1024px) {
  .about-container {
    flex-direction: column;
    padding: 40px;
  }

  .about-text h1 {
    font-size: 2.2rem; /* Slightly larger */
  }

  .about-text p {
    font-size: 1.2rem; /* Slightly larger for tablet */
  }

  .about-image img {
    max-width: 100%;
  }
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .about-container {
    padding: 20px;
    gap: 20px;
  }

  .about-text h1 {
    font-size: 2rem; /* Increased font size for mobile view */
  }

  .about-text p {
    font-size: 1.1rem; /* Increased paragraph size for better readability on mobile */
  }

  .about-image img {
    max-width: 100%;
  }
}
