@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');

.techstack-container {
  background-color: #f4f7f9;
  padding: 60px 20px;
  text-align: center;
}

.techstack-title {
  font-family: 'Montserrat';
  font-size: 3rem;
  margin-bottom: 40px;
  color: #000000;
}

.techstack-grid .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

/* Card Circle */
.card-circle {
  text-align: center;
  background: linear-gradient(135deg, #e6e6fa, #9370db); /* Light to dark lavender gradient background */
  border: 10px solid transparent; /* Transparent border */
  border-radius: 50%;
  padding: 30px;
  width: 261px;
  height: 261px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Required for the border animation */
  transition: all 0.3s ease;
  color: #fff;
  font-family: 'Montserrat';
}

.card-circle::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border-radius: 50%;
  background: linear-gradient(315deg, #d8bfd8, #b399d4, #9370db, #6a5acd); /* Light to dark lavender gradient for border */
  z-index: -1;
  transition: 0.5s;
  animation: borderAnimation 4s linear infinite;
}

@keyframes borderAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-title {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 10px;
}

.tech-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tech-list li {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 5px 0;
}

.tech-list li svg {
  margin-right: 10px;
  font-size: 24px;
}

.card-circle:hover {
  background: linear-gradient(135deg, #d8bfd8, #6a5acd); /* Slightly different lavender gradient on hover */
  color: #000;
}

@media (max-width: 1024px) {
  .card-circle {
    width: 250px;
    height: 250px;
    padding: 20px;
  }

  .card-title {
    font-size: 22px;
  }

  .tech-list li {
    font-size: 14px;
  }

  .tech-list li svg {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .techstack-grid .row {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .card-circle {
    width: 220px;
    height: 220px;
    padding: 15px;
  }

  .card-title {
    font-size: 18px;
  }

  .tech-list li {
    font-size: 12px;
  }

  .tech-list li svg {
    font-size: 18px;
  }
}
