@import url('https://fonts.googleapis.com/css2?family=Roca+One&display=swap');

.services-container {
  background-color: #f4f7f9;
  padding: 60px 20px;
  text-align: center;
}

.services-title {
  font-family: 'Montserrat';
  font-size: 3rem;
  margin-bottom: 40px;
  color: #000000;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

/* Consistent Card Size */
.card-section {
  width: 300px; /* Set a fixed width */
  height: 400px; /* Set a fixed height */
  perspective: 1000px;
}

.service-item,
.card {
  position: relative;
  width: 100%; /* Use the full width of the parent */
  height: 100%; /* Use the full height of the parent */
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 15px;
  overflow: hidden;
}

/* Normal Card Styling */
.service-item {
  background-color: #E6E6FA;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Flip Card Styling */
.card:hover .flip-card__container {
  transform: rotateY(180deg);
}

.flip-card__container {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 70%;
  backface-visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Front Side */
.card-front {
  background-color: #fafbfa;
}

.card-front__tp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  height: 60%; /* Set a consistent height for the top section */
  width: 100%;
}

.card-front__heading {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.card-front__text-view {
  font-size: 1rem;
  margin-top: 10px;
  color: #333;
}

.card-front__tp--city,
.card-front__tp--ski,
.card-front__tp--beach,
.card-front__tp--camping,
.card-front__tp--poster,
.card-front__tp--logo {
  height: 100%; /* Ensure all gradient sections cover the same area */
}

/* Individual Gradient Backgrounds */
.card-front__tp--city {
  background: linear-gradient(to bottom, #ff73b9, #ff40a1);
}

.card-front__tp--ski {
  background: linear-gradient(to bottom, #47c2d7, #279eb2);
}

.card-front__tp--beach {
  background: linear-gradient(to bottom, #fb9b88, #f86647);
}

.card-front__tp--camping {
  background: linear-gradient(to bottom, #00db93, #00b97d);
}

/* New Gradient Backgrounds */
.card-front__tp--poster {
  background: linear-gradient(to bottom, #ff9f43, #ff6f00);
}

.card-front__tp--logo {
  background: linear-gradient(to bottom, #8e44ad, #5e35b1);
}

/* Centered Icon */
.card-front__icon {
  width: 80px;
  height: 80px;
}

/* Back Side */
.card-back {
  background-color: #333;
  color: #fff;
  transform: rotateY(180deg);
}

.card-back__image {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.card-back__description {
  font-size: 1rem;
  text-align: center;
  padding: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-title {
    font-size: 2.5rem;
  }

  .card-section {
    width: 250px; /* Adjust size for smaller screens */
    height: 350px; /* Adjust size for smaller screens */
  }

  .card-front__heading,
  .service-item h2 {
    font-size: 1.2rem;
  }

  .card-back__description {
    font-size: 0.9rem;
  }
}
/* Centered Icon */
.card-front__icon {
  width: 100px; /* Increase the width */
  height: 100px; /* Increase the height */
}
.card-front__tp svg {
  width: 100px; /* Increase the width */
  height: 100px; /* Increase the height */
}
