@import url('https://fonts.googleapis.com/css2?family=Roca+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

.footer-container {
  background-color: #f4f7f9; /* White background */
  color: #000000; /* Dark purple text */
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 70px 60px;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: fadeInUp 1.5s ease forwards;
}

/* Footer Links Section */
.footer-links {
  flex: 1;
  margin: 20px;
  max-width: 200px;
}

.footer-links h4 {
  font-family: 'Montserrat';
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #000000; /* Dark purple text */
  opacity: 0;
  animation: slideInRight 1s ease-in-out forwards 0.3s;
}

/* Remove bullet points */
.footer-nav {
  list-style: none; /* Remove bullet points */
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  padding: 0;
  opacity: 0;
  animation: fadeInUp 1.5s ease-in-out forwards 0.5s;
}

.footer-nav li {
  font-family: 'Montserrat';
  font-size: 1.2rem;
  position: relative;
}

.footer-nav li a {
  color: #000000; /* Dark purple text */
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-nav li a:hover {
  color: #8745a1; /* Lighten the text color on hover */
}

/* Line hover effect: Two lines, one from left and one from right */
.footer-nav li::before,
.footer-nav li::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 0;
  background-color: #8745a1; /* Purple line */
  transition: width 0.3s ease;
}

.footer-nav li::before {
  bottom: -3px;
  left: 0;
}

.footer-nav li::after {
  bottom: -3px;
  right: 0;
}

.footer-nav li:hover::before {
  width: 50%;
}

.footer-nav li:hover::after {
  width: 50%;
}

/* Center Contact Us Section */
.footer-contact {
  flex: 1;
  margin: 20px;
  max-width: 300px;
}

/* Contact Us Animation */
.circleWrap {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
}

.hello {
  margin-top: 65px;
  position: absolute;
  background: transparent;
  color: black;
  font: 3vw/3vw times;
  top: calc(50% - 3vw);
  width: 100%;
  height: 100px;
  text-align: center;
}

.circleWrap svg {
  animation: rotate 10s linear infinite;
  width: 29vw;
  height: 29vw;
  position: absolute;
  top: calc(50% - 15vw);
  left: calc(50% - 15vw);
  overflow: hidden;
  margin-top: 60px;
}

.circleWrap .circle-text {
  font: 100px/100px times;
  letter-spacing: 22.5px;
  width: 100%;
  fill: black;
}

@keyframes rotate {
  to {
    transform: rotate(-360deg);
  }
}

.footer-contact p {
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 200px; /* Adjusted to fit the animation */
  margin-bottom: 20px;
  color: #000000; /* Dark purple text */
  opacity: 0;
  animation: fadeInUp 1.5s ease-in-out forwards 0.7s;
}

/* Follow Us Section */
.footer-follow {
  flex: 1;
  margin: 20px;
  max-width: 300px;
}

.footer-follow h4 {
  font-family: 'Montserrat';
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #000000; /* Dark purple text */
  opacity: 0;
  animation: slideInLeft 1s ease-in-out forwards 0.3s;
}

/* Footer social media icons */
.footer-social {
  display: flex;
  gap: 25px;
  opacity: 0;
  animation: slideInLeft 1s ease-in-out forwards 0.5s;
  margin-left: 35px;
}

.social-icon {
  font-size: 2rem;
  color: #2f105a; /* Dark purple color */
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
}

.instagram {
  color: #E1306C;
}

.phone {
  color: #3498db;
}

.whatsapp {
  color: #25D366;
}

.email {
  color: #b75c53;
}

/* Contact info below social icons */
.footer-contact-info {
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #000000; /* Dark purple text */
  opacity: 0;
  animation: fadeInUp 1.5s ease-in-out forwards 0.7s;
}

.footer-contact-info p {
  margin: 5px 0;
}

.e {
  margin-right: 15px !important;
}

/* Footer copyright */
.footer-copyright {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
  width: 100%;
  border-top: 1px solid #2f105a;
  padding-top: 20px;
  color: #000000; /* Dark purple text */
  opacity: 0;
  animation: fadeInUp 1.5s ease-in-out forwards 1s;
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-links, .footer-contact, .footer-follow {
    text-align: center;
    margin: 10px 0;
  }

  .footer-nav {
    align-items: center;
  }

  .footer-social {
    justify-content: center;
  }

  .footer-contact-info {
    text-align: center;
  }

  /* Adjust animation size for mobile */
  .circleWrap svg {
    width: 40vw; /* Slightly reduce SVG size for mobile */
    height: 40vw;
    left: calc(44% - 17.5vw); /* Center the SVG */
  }

  .circleWrap .circle-text {
    font-size: 30vw; /* Increase the circular text size for mobile */
    letter-spacing: 10px; /* Adjust spacing if needed */
  }

  .hello {
    margin-left: -5px;
    font: 3vw/12vw times; /* Slightly reduce "contact us" text size for mobile */
  }
}

/* Keyframe animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
