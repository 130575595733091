@import url('https://fonts.googleapis.com/css2?family=Roca+One:wght@400;700&family=Roboto:wght@300;500&display=swap');

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 40px;
  background-color: #ffffff;
  animation: fadeIn 1.5s ease-in-out;
  position: relative;
  overflow: hidden;
}

.contact-container::before {
  content: "";
  position: absolute;
  top: -100px;
  left: -100px;
  width: 300px;
  height: 300px;
  background: rgba(138, 52, 219, 0.1);
  border-radius: 50%;
  animation: moveCircle 6s ease-in-out infinite;
}

.contact-container::after {
  content: "";
  position: absolute;
  bottom: -150px;
  right: -150px;
  width: 400px;
  height: 400px;
  background: rgba(68, 48, 225, 0.1);
  border-radius: 50%;
  animation: moveCircleReverse 7s ease-in-out infinite;
}

@keyframes moveCircle {
  0% {
    transform: translateY(0px) translateX(0px);
  }
  50% {
    transform: translateY(50px) translateX(50px);
  }
  100% {
    transform: translateY(0px) translateX(0px);
  }
}

@keyframes moveCircleReverse {
  0% {
    transform: translateY(0px) translateX(0px);
  }
  50% {
    transform: translateY(-50px) translateX(-50px);
  }
  100% {
    transform: translateY(0px) translateX(0px);
  }
}

/* Contact Form */
.contact-form {
  flex: 1;
  max-width: 550px;
  margin-right: 50px;
  background: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transform: translateY(-20px);
  animation: slideUp 1s ease-in-out forwards;
}

.contact-form h2 {
  font-family: 'Montserrat';
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
}

.input-container input,
.input-container textarea {
  font-family: 'Montserrat';
  width: 97%;
  padding: 14px;
  font-size: 1rem;
  border: 2px solid #946edf;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-container input:focus,
.input-container textarea:focus {
  border-color: #946edf;
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.2);
}

.floating-label {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 1rem;
  color: black;
  transition: all 0.3s ease;
  pointer-events: none;
}

.input-container input:focus + .floating-label,
.input-container input:not(:placeholder-shown) + .floating-label,
.input-container textarea:focus + .floating-label,
.input-container textarea:not(:placeholder-shown) + .floating-label {
  top: -12px;
  left: 10px;
  font-size: 0.85rem;
  color: #946edf;
  background-color: #fff;
  padding: 0 4px;
}

/* Submit button */
.contact-form button {
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #946edf;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form button:hover {
  background-color: #7c4bdc;
  transform: translateY(-3px);
}

/* Contact Image Section */
.contact-image img {
  width: 500px;
  animation: slideIn 1s ease-in-out;
  border-radius: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
   100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    padding: 40px 20px;
  }

  .contact-form {
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }

  .contact-image img {
    width: 100%;
  }

  .contact-container::before, 
  .contact-container::after {
    display: none;
  }
  .input-container input,
.input-container textarea {
  width: 90%;
  padding: 14px;
  font-size: 1rem;
  border: 2px solid #946edf;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
}
