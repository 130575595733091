/* Header Background Color */

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 90px;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
    position: sticky; /* Default to sticky for desktop */
    top: 0;
    z-index: 100;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Darker shadow */
    backdrop-filter: blur(10px); /* Blur effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    animation: slideDown 0.6s ease-out; /* Slide animation on load */
}

/* Logo Styling - Slightly increase the size */
.header-logo img {
    height: 100px; /* Adjusted height for a slightly bigger logo */
    transition: transform 0.3s ease;
}
  
/* Optional: Slight hover effect for the logo */
.header-logo img:hover {
    transform: scale(1.1); /* Slight scaling effect on hover */
}
  
/* Navigation Links Styling */
.header-nav {
    display: flex;
}
  
.header-nav-links {
    display: flex;
    list-style: none;
}
  
.header-nav-link {
    margin: 0 15px;
    text-decoration: none;
    color: #000;
    font-weight: 500;
    position: relative;
    transition: color 0.3s ease;
}

/* Double Underline Effect */
.header-nav-link::before, .header-nav-link::after {
    content: '';
    position: absolute;
    bottom: -2px;
    height: 2px;
    background-color: #6b6bff;
    width: 0;
    transition: width 0.4s ease;
}

.header-nav-link::before {
    left: 0;
}

.header-nav-link::after {
    right: 0;
    bottom: -5px;
}

.header-nav-link:hover::before {
    width: 100%;
}

.header-nav-link:hover::after {
    width: 100%;
}

.header-nav-link:hover {
    color: #6b6bff;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

/* Button Styling */
.header-contact-btn .header-contact-link {
    display: inline-block;
    padding: 10px 25px;
    border: 2px solid #000;
    border-radius: 30px;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}

.header-contact-btn .header-contact-link::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: #6b6bff;
    z-index: -1;
    transition: all 0.4s ease-in-out;
}

.header-contact-btn .header-contact-link:hover::before {
    left: 0;
}

.header-contact-btn .header-contact-link:hover {
    color: grey;
    border-color: #6b6bff;
}

/* Hamburger Menu Styling */
.hamburger {
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    transition: all 0.3s ease-in-out;
    z-index: 101;
}

.hamburger .line {
    background-color: #000;
    height: 3px;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.hamburger.is-active .line:nth-child(2) {
    opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

/* Mobile Sidebar Styling */
.header-nav.open {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100vh;
    background-color: #d9d9d9;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 100px;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%); /* Sidebar hidden by default */
}

.header-nav.open {
    transform: translateX(0); /* Sidebar shown when open */
}
/* Mobile View */
@media (min-width: 768px) {
    .header-container {
        position: sticky; /* Change to fixed for mobile */
    }
}
/* Hide on desktop */
/* Mobile View */
@media (max-width: 768px) {
    .header-container {
        position: relative;
    }
    .header-nav {
        display: none;
    }
    .sidebarmbl{
        padding-bottom: 20px;
        justify-content: flex-start;
       }
    .header-contact-btn {
        display: none;
    }
  
    .hamburger {
        display: flex;
    }
  
    .header-nav.open {
        background-color: #ffffff;
        display: flex;
    }

    /* Stack navigation links vertically in mobile view */
    .header-nav-links {
        flex-direction: column; /* Stack the links vertically */
        width: 100%; /* Full width for better alignment */
        padding: 0; /* Remove padding to avoid unnecessary space */
    }

    .header-nav-link {
        padding: 25px 0; /* Increase space between each list item */
        text-align: center; /* Center the text for a cleaner look */
        width: 100%; /* Make sure each link takes the full width */
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Optional: Adds a separator between the links */
        margin-bottom: 15px; /* Add more gap between each <li> */
        font-size: 1.2rem; /* Increase the font size for mobile view */
    }

    /* Remove the border from the last link */
    .header-nav-link:last-child {
        border-bottom: none;
    }

    /* Make Contact Us button visible and aligned in mobile view */
    .header-contact-btn {
        display: block;
        margin-top: 20px; /* Space between the last link and the contact button */
        width: 100%;
        text-align: center;
    }

    .header-contact-btn .header-contact-link {
        width: 80%; /* Adjust button width */
        padding: 10px; /* Ensure enough padding for the button */
        margin: 0 auto; /* Center it horizontally */
    }
}
