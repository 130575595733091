@import url('https://fonts.googleapis.com/css2?family=Roca+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px;
  min-height: 700px;
  height: auto;
  position: relative;
  overflow: hidden;
}

/* Video Background */
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  object-fit: cover; /* Ensure the video covers the entire container */
  z-index: -1; /* Place behind other elements */
}

/* Add an overlay effect */
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 0; /* Position overlay above the video */
}

.hero-text {
  position: relative; /* Position it above the overlay */
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  color: #ffffff; /* White text color for contrast */
  opacity: 0;
  transform: translateY(100%);
  transition: all 1.5s ease-out;
}

.hero-text.slide-in-right {
  opacity: 1;
  transform: translateY(0); /* Move into position */
}

.hero-text h1 {
  font-size: 2.5rem;
  line-height: 1.4;
  max-width: 800px; /* Restrict the width of the text */
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
  .hero-container {
    padding: 50px 20px; /* Adjust padding for smaller screens */
  }

  .hero-text h1 {
    font-size: 1.8rem;
  }

  /* Adjust video for mobile view */
  .hero-video {
    height: 100%; /* Adjust video height */
    object-fit: cover; /* Ensure the video scales properly */
  }
}
