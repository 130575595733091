@import url('https://fonts.googleapis.com/css2?family=Roca+One&display=swap');

/* Main container for the process steps */
.process-container {
    background-color: #ffffff;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* Title for the working process section */
.process-title {
    font-family: 'Montserrat';
    font-size: 3rem;
    color: #000000;
    margin-bottom: 40px;
    text-align: center;
}

/* Desktop grid layout */
.desktop-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for Step 1, Step 2 on top row */
    grid-template-rows: auto auto; /* Two rows */
    gap: 40px;
    justify-items: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}

/* Subtitle styling */
.subtitle {
    font-family: 'Roca One';
    font-size: 1.6rem;
    color: #555;
    margin: 10px 0;
    transition: color 0.3s ease-in-out; /* Transition for hover effect */
}

/* Step 1 -> Step 2 */
.desktop-layout > .step-card:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
}

.desktop-layout > .arrow-icon.horizontal-arrow:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
}

.desktop-layout > .step-card:nth-child(3) {
    grid-column: 3;
    grid-row: 1;
}

/* Down arrow between Step 2 and Step 4 */
.desktop-layout > .arrow-icon.vertical-arrow:nth-child(4) {
    grid-column: 3;
    grid-row: 2;
    justify-self: center;
}

/* Step 4 <- Step 3 */
.desktop-layout > .step-card:nth-child(5) {
    grid-column: 1;
    grid-row: 3;
}

.desktop-layout > .arrow-icon.horizontal-arrow:nth-child(6) {
    grid-column: 2;
    grid-row: 3;
}

.desktop-layout > .step-card:nth-child(7) {
    grid-column: 3;
    grid-row: 3;
}

/* Mobile layout */
.mobile-layout {
    display: none; /* Hidden on desktop, shown on mobile */
}

/* Mobile arrow styling */
.mobile-arrow {
    font-size: 2.5rem;
    color: #555;
    margin: 20px 0;
}

/* Individual step card styling */
.step-card {
    background-color: #d1bcfc;
    color: black;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
}

/* Hover effects */
.step-card:hover {
    transform: translateY(-12px) scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

/* Change subtitle color when step-card is hovered */
.step-card:hover .subtitle {
    color: #6200f4; /* Change color on hover */
}

.step-card h2 {
    font-family: 'Montserrat';
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #333;
}

.step-card p {
    font-family: 'Montserrat';
    font-size: 1.2rem;
    color: black;
    padding: 0 15px;
}

/* Arrow icons (arrows between steps) */
.arrow-icon {
    font-size: 3rem;
    color: #555;
    transition: transform 0.4s ease-in-out;
}

.arrow-icon:hover {
    transform: translateX(10px); /* Right or left based on arrow direction */
}

/* Responsive design */
@media (max-width: 768px) {
    .desktop-layout {
        display: none; /* Hide desktop layout on mobile */
    }

    .mobile-layout {
        display: block; /* Stack steps vertically */
    }

    .mobile-arrow {
        font-size: 2rem; /* Smaller arrows for smaller screens */
    }

    .step-card {
        width: 100%;
        max-width: 280px; /* Reduce card width on mobile */
        height: 180px;     /* Reduce card height on mobile */
        padding: 20px;     /* Reduce padding for smaller screens */
    }

    .step-card h2 {
        font-size: 1.5rem; /* Reduce heading size */
    }

    .step-card p {
        font-size: 1rem;   /* Reduce paragraph size */
    }
}

/* AOS Animations */
[data-aos="fade-up"] {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s ease-out;
}

[data-aos="fade-up"].aos-animate {
    opacity: 1;
    transform: translateY(0);
}
